import React from "react";
import styled from "styled-components";

const Section = ({ children }) => {
  return <Container>{children}</Container>;
};

export default Section;

const Container = styled.div`
  max-width: 800px;
  padding: 100px 30px;
  width: 100%;
`;
