import "./App.css";
import styled from "styled-components";

import Landing from "./sections/Landing/Landing";
import Foundation from "./sections/Foundation/Foundation";

function App() {
  return (
    <div className="App">
      <Page>
        <Landing />
        <Foundation />
      </Page>
    </div>
  );
}

export default App;

const Page = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 300px;
`;
