import React from "react";
import styled from "styled-components";

import Section from "../../components/Section";

const Foundation = () => {
  return (
    <Section>
      <Title>The missing piece</Title>
      <BodyText style={{ marginBottom: 20 }}>
        Nob is built on one basic assumption: everyone wants to lead a good
        life. The definition of "good" may be different. So what is the missing
        piece?{" "}
        <span style={{ fontSize: 20, fontWeight: "bold" }}>
          Intentionality.
        </span>
      </BodyText>
      <BodyText>Hello bruh</BodyText>
    </Section>
  );
};

export default Foundation;

const Title = styled.h2`
  font-size: 36px;
  margin-bottom: 20px;
`;

const BodyText = styled.p`
  font-size: 20px;
  line-height: 24pt;
`;
