import React from "react";
import styled from "styled-components";

const Landing = () => {
  return (
    <Container>
      <div>
        <Title>nob</Title>
        <Subtitle>because only you can open the door.</Subtitle>
      </div>
    </Container>
  );
};

export default Landing;

const Container = styled.div`
  align-items: center;
  background-color: #f9f9f9;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  display: flex;
  height: 120vh;
  justify-content: center;
  min-height: 600px;
  padding: 0px 30px;
  padding-bottom: 130px;
  width: 100%;
`;

const Title = styled.h1`
  font-size: 160px;
  font-weight: 1000;
  margin-bottom: -10px;
  text-align: center;
  user-select: none;
`;

const Subtitle = styled.h2`
  font-size: 26px;
  font-weight: 400;
  text-align: center;
`;
